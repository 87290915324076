const cookieBox = document.querySelector("#cookieBox");
if (cookieBox) {
  const checkboxes = cookieBox.querySelectorAll('input[type=checkbox]:not(:disabled)');
  const cookieBoxModal = new bootstrap.Modal(cookieBox, {
    backdrop: false,
    keyboard: false
  });

  // Get argument to not show cookiebox on "Cookies policy" page
  const URLSearch = new URLSearchParams(window.location.search);
  if (URLSearch.get('ignoreCookieBox') !== '1') {
    cookieBoxModal.show();
  }
  checkboxes.forEach(checkbox => {
    checkbox.addEventListener('change', () => {
      let updateConsent = false;
      checkboxes.forEach(checkbox => {
        if (checkbox.checked) {
          updateConsent = true;
        }
      });
      document.querySelector("#secondaryBtn").querySelectorAll('span').forEach(span => span.classList.add('d-none'));
      if (updateConsent) {
        document.querySelector("#updateConsent").classList.remove('d-none');
      } else {
        document.querySelector("#onlyNecessary").classList.remove('d-none');
      }
    });
  });
  const cookieExtraInfoBtn = document.querySelector("#cookieExtraInfoBtn");
  cookieExtraInfoBtn.addEventListener('click', e => {
    const spans = cookieExtraInfoBtn.querySelectorAll('span');
    spans.forEach(span => span.classList.toggle('d-none'));
  });
}
const changeCookieLinks = document.querySelectorAll('.changeCookie');
changeCookieLinks.forEach(changeCookieLink => {
  changeCookieLink.addEventListener('click', e => {
    e.preventDefault();
    fetch("/global/ajaxHandlerGlobal.php?handler=changeCookie").then(response => response.json().then(response => {
      if (!response.error) {
        location.reload();
      }
    }));
  });
});